(function() {
    'use strict';
    angular.module('tjb', ['ngMessages', 'ngSanitize', 'ngRoute']);

    angular.module('tjb').config(["$routeProvider", "$locationProvider", "$sceDelegateProvider", "$compileProvider", "$httpProvider", "tjbConfig", function($routeProvider, $locationProvider, $sceDelegateProvider, $compileProvider, $httpProvider, tjbConfig) {
        
        var urlWhiteList = [
            'self',
            'https://mlcdn-*.storage.googleapis.com/**',
            'https://*.netsuite.com/**',
            'https://*.mlahart.com/**',
            'http://*.mlahart.com/**'
        ];

        $compileProvider.debugInfoEnabled(tjbConfig.dev);
        //$compileProvider.debugInfoEnabled(true);
        $sceDelegateProvider.resourceUrlWhitelist(urlWhiteList);
 
        $routeProvider
            .when('/', {
                template: '<tjb-home></tjb-home>'
            })
            .when('/Order-Entry', {
                template: '<tjb-order-entry data-order="$tjb.order.ns"></tjb-order-entry>',
                resolve: {
                    order: ["tjbOrderService", function(tjbOrderService) {
                        return tjbOrderService.init();
                    }]
                },
                resolveAs: '$tjb'
            })
            .otherwise({
                redirectTo: '/'
            });

        $locationProvider.html5Mode(true);
    }]);
}());
(function() {
    'use strict';
    // console.log("constant");
    var config = {};

    config.giftText = 'If the customer would like to add a gift message, enter it here. There is a maximum of 150 Characters';
    config.repText = 'If you have any issues, or need to provide additional information, add it here. This information is for M.LaHart Staff and will not be seen by the customer.';
    config.promoText = 'If the customer asks to use a promo code, enter it here';
    config.catalog_code_text = 'Can I have the Source Code from the back of your catalog, in the yellow box next to your address?';
    config.how_did_you_hear_text = 'How did you hear about us?';
    config.how_did_you_hear_prefix = 'If no Source Code...';
    config.nsRoot = '/ns/account-dev/';
    config.itemFile = '/static/tjb/tjb-orderEntryKits2.json?ver=2023_12_15';
    config.apiRoot = config.nsRoot + 'services/';
    config.imgRoot = 'https://img.mlahart.com/';
    config.dataSrc = 'data.json';
    config.dev = false;

    // order_entry_items.json

    config.lsKeys = {
        login: 'templogin',
        view: 'tab',
        items: 'savedKits',
        accessrole: 'accessrole',
        salesrep: 'salesrep',
        templogincreated: 'templogincreated'
    };

    config.lsVals = {
        items: 'savedKits',
        accessrole: '1021',
        salesrep: '22780'
    };

    config.login = false;
    config.view = false;
    config.items = false;
    config.accessrole = false;
    config.salesrep = false;

    config.delivery_information = '/api/delivery-information';
    config.live_notes = true;

    try {
        config.login = JSON.parse(localStorage.getItem(config.lsKeys.login)) || false;
        config.login_created = JSON.parse(localStorage.getItem(config.lsKeys.templogincreated)) || false;
        config.view = JSON.parse(localStorage.getItem(config.lsKeys.view)) || false;
        config.items = JSON.parse(localStorage.getItem(config.lsKeys.items)) || false;
        config.accessrole = JSON.parse(localStorage.getItem(config.lsKeys.accessrole)) || false;
        config.salesrep = JSON.parse(localStorage.getItem(config.lsKeys.salesrep)) || false;
        //config.dev = (localStorage.getItem('dev_mode') === 'true') || false;
    } catch (er) {
        console.log(er);
    }
    if (config.dev) console.log(config);

    if (config.login) {
        if (!config.login_created) config.login = false;
    }

    angular.module('tjb').constant('tjbConfig', config);

    window.oe_config = window.oe_config || {};

    window.oe_config.dev = function() {
        config.dev = !config.dev;
        localStorage.setItem('dev_mode', config.dev);
        window.location.reload();
    };


}());
(function() {
    'use strict';
    angular.module('tjb').directive('contenteditable', function() {
        return {
            require: 'ngModel',
            link: function(scope, elm, attrs, ctrl) {
                elm.on('blur', function() {
                    ctrl.$setViewValue(elm.html());
                });
                ctrl.$render = function() {
                    elm.html(ctrl.$viewValue);
                };
                ctrl.$setViewValue(elm.html());
            }
        };
    });

    angular.module('tjb').filter('iSrc', ["tjbConfig", function(tjbConfig) {
        return function(input) {
            var o;
            if (angular.isString(input)) {
                o = tjbConfig.imgRoot + input;
            }
            return o;
        };
    }]);

    angular.module('tjb').filter('hasProps', function() {
        return function(obj) {
            function isNotEmptyObject(o) {
                for (var prop in o) {
                    if (Object.prototype.hasOwnProperty.call(o, prop)) {
                        return true;
                    }
                }
                return false;
            }
            return angular.isObject(obj) && isNotEmptyObject(obj);
        };
    });

    angular.module('tjb').filter('noAddressId', function() {
        return function(str) {
            return angular.isString(str) && str.indexOf('null') !== -1;
        };
    });

    var patterns = {
        // Matches any digit(s) (i.e. 0-9)
        digits: /^\d+$/,

        // Matched any number (e.g. 100.000)
        number: /^-?(?:\d+|\d{1,3}(?:,\d{3})+)(?:\.\d+)?$/,

        // Matches a valid email address (e.g. mail@example.com)
        email: /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i,

        // Mathes any valid url (e.g. http://www.xample.com)
        url: /^(https?|ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i
    };

    angular.module('tjb').directive('tjbemail', function() {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function(scope, element, attr, ngModel) {
                var pattern = patterns[attr.tjbemail];

                ngModel.$validators.tjbemail = function(modelValue, viewValue) {
                    var value = modelValue || viewValue;
                    if (!value || value === null || value === 'undefined') {
                        return true;
                    } else {
                        return value.match(pattern);
                    }
                };

            }
        };
    });

}());

(function() {
    'use strict';
    var tjbHomeCtrl = function(tjbConfig) {

        var ctrl = this;


        ctrl.$onInit = function(obj) {

        };
    };

    angular.module('tjb').component('tjbHome', {
        templateUrl: 'components/tjb-home.html',
        controller: ['tjbConfig', tjbHomeCtrl],
        controllerAs: '$ctrl'
    });
}());
(function() {
    'use strict';
    var tjbOrderEntryCtrl = function(tjbConfig, tjbNsapi, tjbOrderService, $timeout) {

        var ctrl = this;

        ctrl.header = 'components/partials/tjb-header.html';
        ctrl.footer = 'components/partials/tjb-footer.html';

        ctrl.removeError = function(error) {
            tjbOrderService.removeError(error);
        };

        ctrl.newSession = function() {
            tjbNsapi.reset_all(function(res) {
                if (tjbConfig.dev) console.log(res);
                window.location.reload();
            });
        };

        ctrl.$onChanges = function(obj) {};

        ctrl.$onInit = function(obj) {

            ctrl.processing = tjbNsapi.processing;

            if ((!tjbConfig.accessrole || !tjbConfig.salesrep) && ctrl.order && ctrl.order.user) {
                tjbNsapi.auditUser(ctrl.order.user.internalid, function(res) {
                    if (tjbConfig.dev) console.log('tjbNsapi.auditUser - res', res);
                });
            }

        };
    };

    angular.module('tjb').component('tjbOrderEntry', {
        templateUrl: 'components/tjb-order-entry.html',
        controller: ['tjbConfig', 'tjbNsapi', 'tjbOrderService', '$timeout', tjbOrderEntryCtrl],
        controllerAs: '$ctrl',
        bindings: {
            items: '<',
            order: '<'
        }
    });
}());
(function() {
    'use strict';
    var tjbGiftMessageCtrl = function(tjbConfig, tjbNsapi, tjbOrderService) {
        var ctrl = this;
        ctrl.processing = false;
        ctrl.helpText = tjbConfig.giftText;

        ctrl.submit = function() {
            ctrl.processing = true;
            tjbNsapi.updateOrderCustomFields({ 'custbody_messageforgift': ctrl.message }, function(res) {
                ctrl.message = '';
                console.log(res);
                ctrl.processing = false;
            });
        };

        ctrl.deleteCustomField = function(obj) {
            ctrl.processing = true;
            tjbNsapi.updateOrderCustomFields(obj, function(res) {
                ctrl.processing = false;
            });
        };

        ctrl.$onInit = function(obj) {
            ctrl.cart = tjbOrderService.getCart();
        };
    };
    angular.module('tjb').component('tjbGiftMessage', {
        templateUrl: 'components/gift-message/gift-message.html',
        controller: ['tjbConfig', 'tjbNsapi', 'tjbOrderService', tjbGiftMessageCtrl],
        controllerAs: '$ctrl'
    });
}());
(function() {
    'use strict';
    var tjbSalesRepMessageCtrl = function(tjbConfig, tjbNsapi, tjbOrderService) {
        var ctrl = this;
        ctrl.processing = false;
        ctrl.helpText = tjbConfig.repText;

        ctrl.submit = function() {
            ctrl.processing = true;
            tjbNsapi.updateOrderCustomFields({ 'custbody_orderentrymessagefromsalesrep': ctrl.message }, function(res) {
                ctrl.message = '';
                console.log(res);
                ctrl.processing = false;
            });
        };

        ctrl.deleteCustomField = function(obj) {
            ctrl.processing = true;
            tjbNsapi.updateOrderCustomFields(obj, function(res) {
                ctrl.processing = false;
            });
        };

        ctrl.$onInit = function(obj) {
            ctrl.cart = tjbOrderService.getCart();
        };
    };
    angular.module('tjb').component('tjbSalesRepMessage', {
        templateUrl: 'components/sales-rep-message/sales-rep-message.html',
        controller: ['tjbConfig', 'tjbNsapi', 'tjbOrderService', tjbSalesRepMessageCtrl],
        controllerAs: '$ctrl'
    });
}());
(function() {
    "use strict";

    var tjbItemService = function($http, $q, tjbConfig, $filter) {
        var data = {};

        data.kitsById = {};
        data.kitsBySku = {};
        data.featured = {};
        data.featured.kit = {};
        data.options = {};
        data.selected = {};
        data.search = {};
        data.search.text = '';
        data.search.limit = 20;
        data.search.start = 0;
        data.search.length = 0;
        data.search.current = '';
        data.filteredKits = [];

        var obj = {};

        var textFilter = $filter('filter');

        function updateSearchstats() {
            data.search.length = data.filteredKits.length;
            data.search.from = 1 + data.search.start;
            data.search.to = data.search.start + data.search.limit;
            data.search.next = (data.search.length > data.search.to);
            data.search.prev = (data.search.start > 0);
            data.search.more = (data.search.length > data.search.limit);
            data.search.plus = (data.search.limit <= 40);
            data.search.minus = (data.search.limit >= 10);
            data.search.noresults = false;
        }

        obj.getFilteredKits = function() {
            return data.filteredKits;
        };

        obj.setSearchLimit = function(limit) {
            data.search.limit = limit;
            updateSearchstats();
        };

        obj.setSearchStart = function(start) {
            data.search.start = start;
            updateSearchstats();
        };

        obj.searchNext = function() {
            if ((data.search.start + data.search.limit) < data.search.length) {
                data.search.start = data.search.start + data.search.limit;
                updateSearchstats();
            } else {
                data.search.start = data.search.length - data.search.limit;
                updateSearchstats();
            }
        };

        obj.searchPrev = function() {
            if ((data.search.start - data.search.limit) > 0) {
                data.search.start = data.search.start - data.search.limit;
                updateSearchstats();
            } else {
                data.search.start = 0;
                updateSearchstats();
            }
        };

        obj.searchLimitMinus = function() {
            if (data.search.limit > 20) {
                data.search.limit = data.search.limit - 10;
            } else {
                data.search.limit = 10;
            }
            updateSearchstats();
        };

        obj.searchLimitPlus = function() {
            if (data.search.limit < 40) {
                data.search.limit = data.search.limit + 10;
            } else {
                data.search.limit = 50;
            }
            updateSearchstats();
        };

        obj.setSearchText = function(text) {
            data.search.text = text;
            if (text.length > 2) {
                data.filteredKits = textFilter(data.kits, data.search.text);
                updateSearchstats();
            } else {
                data.filteredKits = [];
                updateSearchstats();
            }
        };

        obj.setSearchTextNs = function(text) {
            data.search.textns = text;
            if (text.length === 0) {
                data.filteredKits = [];
                updateSearchstats();
            }
        };

        obj.setCurrentFocus = function(f) {
            if (data.search.current === f) return;
            data.search.current = f;
            data.search.textns = '';
            data.search.text = '';
            data.filteredKits = [];
            updateSearchstats();
        };

        obj.setSearchById = function(ids) {
            if (ids) {
                data.filteredKits = textFilter(data.kits, function(obj, index, array) {
                    return ids.indexOf(obj.internalid) !== -1;
                });
                updateSearchstats();
            } else {
                data.filteredKits = [];
                updateSearchstats();
            }
            if (data.filteredKits.length === 0 && data.search.text.length > 0) {
                data.search.noresults = true;
            }
        };

        obj.getSearch = function() {
            return data.search;
        };

        function setSavedKits() {
            //console.log('setSavedKits');
            var ids = [];
            angular.forEach(data.selected, function(val, key) {
                if (val) ids.push(val.internalid);
            });
            localStorage.setItem('savedKits', JSON.stringify(ids));
        }

        obj.featureKit = function(internalid) {
            //console.log('obj.featureKit');
            var kit = data.kitsById[internalid];

            data.featured.kit = data.kitsById[internalid];

            if (!data.selected[internalid]) {
                data.selected[internalid] = kit;
                setSavedKits();
            }

            if (!data.featured.kit.nsKit) {
                $http.get(tjbConfig.apiRoot + 'getKit.ss?id=' + internalid).then(function(res) {
                    data.featured.kit.nsKit = res.data[0];
                    //console.log('nsKit - res', res);
                }, function(err) {
                    console.log(err);
                });
            }

            obj.setSearchText('');
        };

        obj.featureKitSKU = function(sku, variant) {
            //console.log('obj.featureKit');
            var item = data.kitsBySku[sku];

            if (item) {
                data.featured.kit = item;
                if (!data.selected[item.internalid]) {
                    data.selected[item.internalid] = item;
                    if(variant) item.VARIANT = variant;
                    setSavedKits();
                }
            } else {
                $http.get('/json/items/' + sku + '.json').then(function(res) {

                    var item = res.data;

                    data.kitsBySku[item.sku] = item;

                    data.selected[item.internalid] = item;
                    
                    data.featured.kit = item;
                    
                    if(variant) item.VARIANT = variant;
                    //console.log('nsKit - res', res);
                }, function(err) {
                    console.log(err);
                });
            }
            obj.setSearchText('');

            /*
            var kit = data.kitsBySku[sku];

            data.featured.kit = data.kitsBySku[sku];

            if (!data.selected[kit.internalid]) {
                data.selected[kit.internalid] = kit;
                setSavedKits();
            }

            if (!data.featured.kit.nsKit) {
                $http.get('/json/items/' + sku + '.json').then(function(res) {
                    data.featured.kit.nsKit = res.data;
                    //console.log('nsKit - res', res);
                }, function(err) {
                    console.log(err);
                });
            }

            obj.setSearchText('');
            */
        };

        obj.getFeatureKit = function() {
            return data.featured.kit;
        };

        obj.removeFeatured = function(internalid) {
            data.featured.kit = {};
        };

        obj.removeKit = function(internalid) {
            delete data.selected[internalid];
            setSavedKits();
        };

        obj.getData = function() {
            return data;
        };

        function processKits(kits, opts, callback) {
            var lsList, optsArr, i, len, o;

            //console.log('kits', kits);

            // Attach Kits Array
            data.kits = kits;

            // Create index object by id and attach options while itterating
            angular.forEach(data.kits, function(kit, key) {
                if (kit.matrix) {
                    kit.matrix.lists = kit.matrix.options;
                }
                data.kitsById[kit.internalid] = kit;
                data.kitsBySku[kit.sku] = kit;
                optsArr = [];
                i = 0;
                //if(!kit.options) console.log('kit', kit);
                len = (kit.options && kit.options.length) ? kit.options.length : 0;
                if (len === 0) return;

                function do_it(i) {
                    var o = {};
                    try {
                        if (!kit.options[i] || kit.options[i] === 'custcol139') return;
                        o = opts.options[kit.options[i]];
                        if (!o) return;

                        if (o.options) o.lists = opts.lists[o.listId];

                        if (o.name === 'custcol122') o.hidden = true;

                        optsArr.push(o);
                    } catch (er) {
                        console.log('er', er);
                        console.log('kit', kit);
                        console.log('opts', opts);
                    }
                }

                for (i; i < len; i += 1) {
                    do_it(i);
                }

                data.options[kit.internalid] = optsArr;
            });

            // Look for a saved list in storage
            try {
                lsList = JSON.parse(localStorage.getItem('savedKits')) || {};
            } catch (err) {
                console.log(err);
                lsList = {};
            }


            // Itterate over ids and add them to the seleceted kits
            angular.forEach(lsList, function(id) {
                data.selected[id] = data.kitsById[id];
            });

            // Send the data obj back
            return callback(obj.getData());
        }

        obj.init = function() {
            var defer = $q.defer();

            function success(obj) {
                //console.log('obj', obj);
                processKits(obj.data.kits, obj.data.opts, function(obj) {
                    defer.resolve(obj);
                });
            }

            function error(err) {
                defer.reject(err);
            }

            $http({
                url: tjbConfig.itemFile,
                method: 'GET',
                cache: true
            }).then(success, error);

            return defer.promise;

        };

        return obj;
    };

    angular.module('tjb').factory('tjbItemService', ['$http', '$q', 'tjbConfig', '$filter', tjbItemService]);
}());
(function() {
    "use strict";

    var tjbNsapi = function($http, tjbConfig, tjbOrderService) {

        var o = {};

        o.processing = {};
        o.processing.status = false;

        o.sPt = function() {
            o.processing.status = true;
        };

        o.sPf = function() {
            o.processing.status = false;
        };

        o.getP = function() {
            return o.processing;
        };

        function errorGeneral(err, callback) {
            console.log(err);
            return callback && callback(false);
        }

        function successGeneral(data, callback) {
            if (data.errorMessage) {
                tjbOrderService.addError(data);
            }
            return callback && callback(data);
        }

        function successCart(data, callback) {
            if (!data.errorMessage) {
                tjbOrderService.updateCart(data);
                return callback && callback(data);
            } else {
                tjbOrderService.addError(data);
                return callback && callback(data);
            }
        }

        function successAddress(data, callback) {
            if (data.errorMessage) {
                tjbOrderService.addError(data);
                return callback && callback(data);
            } else {
                tjbOrderService.updateAddressList(data);
                return o.getCart(callback);
            }
        }

        function successData(data, callback) {
            if (!data.errorMessage) {
                tjbOrderService.updateData(data);
            } else {
                tjbOrderService.addError(data);
            }
            return callback && callback(data);
        }


        function syncOrder(callback) {
            if (tjbConfig.dev) console.log("|-- nsapi syncOrder Called -->");
            $http.get(tjbConfig.apiRoot + 'live-order.ss').then(function(res) {
                return successCart(res.data, callback);
            }, function(err) {
                return errorGeneral(err, callback);
            });
        }

        function ns_logout(cb) {
            if (tjbConfig.dev) console.log("|-- nsapi logout Called -->");
            $http.post(tjbConfig.apiRoot + 'logout.ss').then(function(res) {
                if (tjbConfig.dev) console.log("|-- nsapi logout Complete -->");
                if (cb) cb(res);
            }, function(err) {
                console.log("|-- nsapi logout Error -->");
                console.log(err);
            });
        }


        function deleteAllCookies() {
            var cookies = document.cookie.split("; ");
            for (var c = 0; c < cookies.length; c++) {
                var d = window.location.hostname.split(".");
                while (d.length > 0) {
                    var cookieBase = encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + d.join('.') + ' ;path=';
                    var p = location.pathname.split('/');
                    document.cookie = cookieBase + '/';
                    while (p.length > 0) {
                        document.cookie = cookieBase + p.join('/');
                        p.pop();
                    }
                    d.shift();
                }
            }
        }

        o.reset_all = function(cb) {
            if (tjbConfig.dev) console.log("|-- nsapi reset_all Called -->");

            deleteAllCookies();
            if (tjbConfig.dev) console.log("|-- nsapi deleteAllCookies Complete -->");

            window.localStorage.clear(); 
            if (tjbConfig.dev) console.log("|-- nsapi localStorage.clear Complete -->");

            $http.post(tjbConfig.apiRoot + 'empty-cart.ss',{action: 'empty'}).then(function(res) {
                if (tjbConfig.dev) console.log("|-- nsapi empty cart Complete -->");
                successCart(res.data);
                ns_logout(cb);

            }, function(err) {
                console.log("|-- nsapi empty cart Error -->");
                console.error(err);
            });             
        };

        o.accountForgotPassword = function(obj, callback) {
            if (tjbConfig.dev) console.log("|-- nsapi accountForgotPassword Called -->");
            $http.post(tjbConfig.apiRoot + 'account-forgot-password.ss', obj).then(function(res) {
                console.log("|-- nsapi accountForgotPassword Complete -->");
                return callback && callback(res.data);
            }, function(err) {
                console.log("|-- nsapi accountForgotPassword Error -->");
                console.log(err);
                return callback && callback(false);
            });
        };

        o.accountLogin = function(obj, callback) {
            if (tjbConfig.dev) console.log("|-- nsapi accountLogin Called -->");
            $http.post(tjbConfig.apiRoot + 'account-login.ss', obj).then(function(res) {

                console.log(res);
                return successData(res.data, callback);
            }, function(err) {
                console.log(err);
                return errorGeneral(err, callback);
            });
        };

        o.getAddressList = function(callback) {
            if (tjbConfig.dev) console.log("|-- nsapi getAddressList Called -->");
            $http.get(tjbConfig.apiRoot + 'address.ss').then(function(res) {
                return successAddress(res.data, callback);
            }, function(err) {
                return errorGeneral(err, callback);
            });
        };

        o.addAddress = function(obj, callback) {
            if (tjbConfig.dev) console.log("|-- nsapi addAddress Called -->");
            $http.post(tjbConfig.apiRoot + 'address.ss', obj).then(function(res) {
                return successAddress(res.data, callback);
            }, function(err) {
                return errorGeneral(err, callback);
            });
        };

        o.updateAddress = function(obj, callback) {
            if (tjbConfig.dev) console.log("|-- nsapi updateAddress Called -->");
            $http.put(tjbConfig.apiRoot + 'address.ss?internalid=' + obj.internalid, obj).then(function(res) {
                return successAddress(res.data, callback);
            }, function(err) {
                return errorGeneral(err, callback);
            });
        };

        o.removeAddress = function(obj, callback) {
            if (tjbConfig.dev) console.log("|-- nsapi removeAddress Called -->");
            $http.delete(tjbConfig.apiRoot + 'address.ss?internalid=' + obj.internalid).then(function(res) {
                return successAddress(res.data, callback);
            }, function(err) {
                return errorGeneral(err, callback);
            });
        };

        o.setPaymentMethods = function(obj, callback) {
            if (tjbConfig.dev) console.log("|-- nsapi setPaymentMethods Called -->");
            $http.post(tjbConfig.apiRoot + 'live-order-set-payment.ss', obj).then(function(res) {
                return successCart(res.data, callback);
            }, function(err) {
                return errorGeneral(err, callback);
            });
        };

        o.removePaymentMethods = function(callback) {
            if (tjbConfig.dev) console.log("|-- nsapi removePaymentMethods Called -->");
            $http.delete(tjbConfig.apiRoot + 'live-order-set-payment.ss').then(function(res) {
                return successCart(res.data, callback);
            }, function(err) {
                return errorGeneral(err, callback);
            });
        };

        o.submitOrder = function(callback) {
            if (tjbConfig.dev) console.log("|-- nsapi submitOrder Called -->");
            $http.post(tjbConfig.apiRoot + 'live-order-submit.ss').then(function(res) {
                if (res.data.errorCode) {
                    callback(res.data);
                } else {
                    return successCart(res.data, callback);
                }
            }, function(err) {
                return errorGeneral(err, callback);
            });
        };

        o.submitAsError = function(callback) {
            if (tjbConfig.dev) console.log("|-- nsapi submitOrderInvoice Called -->");
            $http.post(tjbConfig.apiRoot + 'invoice-override.ss', {
                paymentmethod: '14',
                paymentterms: ''
            }).then(function(res) {
                return successCart(res.data, callback);
            }, function(err) {
                return errorGeneral(err, callback);
            });
        };

        o.addCreditCard = function(obj, callback) {
            if (tjbConfig.dev) console.log("|-- nsapi addCreditCard Called -->");
            $http.post(tjbConfig.apiRoot + 'creditcard.ss', obj).then(function(res) {
                callback(res);
            }, function(err) {
                console.log(err);
                if (callback) callback(false);
            });
        };

        o.addToCart = function(obj, callback) {
            if (tjbConfig.dev) console.log("|-- nsapi addToCart Called -->");
            $http.post(tjbConfig.apiRoot + 'cart.ss', obj).then(function(res) {
                return successCart(res.data, callback);
            }, function(err) {
                return errorGeneral(err, callback);
            });
        };

        o.removeFromCart = function(obj, callback) {
            if (tjbConfig.dev) console.log("|-- nsapi removeFromCart Called -->");
            $http.post(tjbConfig.apiRoot + 'cart.ss', obj).then(function(res) {
                return successCart(res.data, callback);
            }, function(err) {
                return errorGeneral(err, callback);
            });
        };

        o.updateItem = function(obj, id, callback) {
            if (tjbConfig.dev) console.log("|-- nsapi updateItem Called -->");
            $http.put(tjbConfig.apiRoot + 'live-order-line.ss?internalid=' + id, obj).then(function(res) {
                return successCart(res.data, callback);
            }, function(err) {
                return errorGeneral(err, callback);
            });
        };

        o.updateOrder = function(obj, callback) {
            if (tjbConfig.dev) console.log("|-- nsapi updateOrder Called -->");
            $http.put(tjbConfig.apiRoot + 'live-order.ss', obj).then(function(res) {
                return successCart(res.data, callback);
            }, function(err) {
                return errorGeneral(err, callback);
            });
        };

        o.updateOrderCustomFields = function(obj, callback) {
            if (tjbConfig.dev) console.log("|-- nsapi updateOrderCustomFields Called -->");
            $http.put(tjbConfig.apiRoot + 'tjb-order-custom-fields.ss', obj).then(function(res) {
                return successCart(res.data, callback);
            }, function(err) {
                return errorGeneral(err, callback);
            });
        };

        o.getCart = function(callback) {
            if (tjbConfig.dev) console.log("|-- nsapi getCart Called -->");
            $http.get(tjbConfig.apiRoot + 'live-order.ss').then(function(res) {
                return successCart(res.data, callback);
            }, function(err) {
                return errorGeneral(err, callback);
            });
        };

        o.sync = function(callback) {
            if (tjbConfig.dev) console.log("|-- nsapi sync Called -->");
            return o.getCart(callback);
        };

        o.setAddress = function(obj, callback) {
            if (tjbConfig.dev) console.log("|-- nsapi setAddress Called -->");
            $http.post(tjbConfig.apiRoot + 'tjb-order-address.ss', obj).then(function(res) {
                return successCart(res.data, callback);
            }, function(err) {
                return errorGeneral(err, callback);
            });
        };

        o.updateShipping = function(shipmethodid, callback) {
            if (tjbConfig.dev) console.log("|-- nsapi updateShipping Called -->");
            $http.post(tjbConfig.apiRoot + 'live-order-shipmethod.ss', { shipmethod: shipmethodid }).then(function(res) {
                return successCart(res.data, callback);
            }, function(err) {
                return errorGeneral(err, callback);
            });
        };

        o.updatePromo = function(obj, callback) {
            if (tjbConfig.dev) console.log("|-- nsapi updatePromo Called -->");
            $http.post(tjbConfig.apiRoot + 'live-order-promocode.ss', obj).then(function(res) {
                return successCart(res.data, callback);
            }, function(err) {
                return errorGeneral(err, callback);
            });
        };

        o.setCustomFieldValues = function(obj, callback) {
            if (tjbConfig.dev) console.log("|-- nsapi setCustomFieldValues Called -->");
            $http.post(tjbConfig.apiRoot + 'live-order-custom-fields.ss', obj).then(function(res) {
                return callback(res);
                // return successCart(res.data, callback);
            }, function(err) {
                return errorGeneral(err, callback);
            });
        };

        o.deletePromo = function(callback) {
            if (tjbConfig.dev) console.log("|-- nsapi deletePromo Called -->");
            $http.delete(tjbConfig.apiRoot + 'live-order-promocode.ss').then(function(res) {
                return successCart(res.data, callback);
            }, function(err) {
                return errorGeneral(err, callback);
            });
        };

        o.register = function(obj, callback) {
            if (tjbConfig.dev) console.log("|-- nsapi register Called -->");
            $http.post(tjbConfig.apiRoot + 'account-register.ss', obj).then(function(res) {
                return successData(res.data, callback);
            }, function(err) {
                return errorGeneral(err, callback);
            });
        };

        o.updateProfile = function(obj, callback) {
            if (tjbConfig.dev) console.log("|-- nsapi updateProfile Called -->");
            $http.post(tjbConfig.apiRoot + 'profile.ss', obj).then(function(res) {
                callback(res.data);
                // return successData(res.data, callback);
            }, function(err) {
                return errorGeneral(err, callback);
            });
        };

        o.tagOEUser = function(callback) {
            if (tjbConfig.dev) console.log("|-- nsapi tagOEUser Called -->");
            $http.post(tjbConfig.apiRoot + 'tjbx.ss', { action: oeuserinit }).then(function(res) {
                return callback(res.data);
            }, function(err) {
                return callback(err);
            });
        };

        o.tjbx = function(obj, callback) {
            if (tjbConfig.dev) console.log("|-- nsapi tjbx Called -->");
            $http.post(tjbConfig.apiRoot + 'tjbx.ss', obj).then(function(res) {
                callback(res.data);
            }, function(err) {
                return errorGeneral(err, callback);
            });
        };

        o.auditUser = function(userid, callback) {
            if (tjbConfig.dev) console.log("|-- nsapi auditUser Called -->");
            var update = false;

            var obj = {};
            obj.action = 'getrecfields';
            obj.recType = 'customer';
            obj.recId = userid;
            obj.getValue = [tjbConfig.lsKeys.salesrep, tjbConfig.lsKeys.accessrole];

            var login_creds;

            try {
                login_creds = localStorage.getItem(tjbConfig.lsKeys.login);
            } catch (err) {
                console.error(err);
            }

            try {
                o.tjbx(obj, function(res) {

                    if (res[tjbConfig.lsKeys.salesrep] === tjbConfig.lsVals.salesrep) {
                        localStorage.setItem(tjbConfig.lsKeys.salesrep, JSON.stringify(tjbConfig.lsVals.salesrep));
                    } else {
                        update = update || {};
                        update.setValue = update.setValue || {};
                        update.setValue[tjbConfig.lsKeys.salesrep] = tjbConfig.lsVals.salesrep;
                    }

                    if (res[tjbConfig.lsKeys.accessrole] === tjbConfig.lsVals.accessrole) {
                        localStorage.setItem(tjbConfig.lsKeys.accessrole, JSON.stringify(tjbConfig.lsVals.accessrole));
                    } else {
                        update = update || {};
                        update.setValue = update.setValue || {};
                        update.setValue[tjbConfig.lsKeys.accessrole] = tjbConfig.lsVals.accessrole;
                    }

                    if (update) {
                        update.action = 'updaterec';
                        update.recType = 'customer';
                        update.recId = userid;

                        o.tjbx(update, function(res) {
                            //console.log('auditUser - res', res);
                            //console.log('auditUser - login_creds', login_creds);
                            //console.log('auditUser - tjbConfig', tjbConfig);
                            //window.location.reload();

                            $http({
                                method: 'POST',
                                url: tjbConfig.apiRoot + 'account-login.ss',
                                data: login_creds,
                                cache: false
                            }).then(function(res) {
                                callback(res);
                            }, function(err) {
                                callback(res);
                            });


                            //return callback(res);
                        });
                    } else {
                        return callback(res);
                    }
                });
            } catch (err) {
                return callback(err);
            }
        };

        return o;
    };

    angular.module('tjb').factory('tjbNsapi', ['$http', 'tjbConfig', 'tjbOrderService', tjbNsapi]);
}());
(function() {
    "use strict";

    var optionService = function(options, lists) {
        var data = {
            options: false
        };

        var obj = {};

        obj.get_options = function(){

            if(!data.options){
                obj.init();
            }

            return data.options;
        };

        obj.get_option = function(key){

            if(!data.options){
                obj.init();
            }

            return data.options[key];
        };

        obj.init = function() {
            if (!data.options) {

                data.options = {};

                angular.forEach(options, function(obj, key) {
                    if (obj.name === 'custcol122') obj.hidden = true;
                    if (obj.name === 'custcol139') obj.hidden = true;

                    if (obj.listId) {
                        obj.options = lists[obj.listId];
                    }

                    data.options[key] = obj;
                });
                //console.log('data.options', data.options);
                //console.log('options', options);
                //console.log('lists', lists);

            }

        };

        return obj;
    };

    angular.module('tjb').factory('optionService', ['options', 'lists', optionService]);
}());
(function() {
    "use strict";

    var tjbOrderService = function(tjbConfig, $http, $q, $rootScope, delivery) {
        var DEV = tjbConfig.dev;

        var obj = {};

        var data = {};

        data.ns = {};
        data.ns.state = {};
        data.ns.addressObj = {};
        data.ns.state.errors = [];


        function get_delivery(sku, postal, callback) {
            if (postal) {
                delivery.get_detailed_note(sku, postal, callback);
            } else {
                delivery.get_note(sku, callback);
            }
        }

        function create_note(delivery_obj, item) {
            var note = '';

            if (delivery_obj.arrives) {
                note += 'Estimated delivery date: ' + delivery_obj.arrives + ' via standard ground service';
            } else if (delivery_obj.note) {
                note += delivery_obj.note.replace(/\n/g, '<br>');
            } else {
                note += 'Currently unable to get delivery information';
            }

            return note;
        }


        function updateCartInfo() {
            if (DEV) console.log("|-- orderService updateCartInfo Called -->");

            var express = true;
            var Cart = data.ns.cart;
            var State = data.ns.state;
            var Address = data.ns.addressObj;

            State.promocode = Cart.promocode;
            State.itemsincart = (Cart.lines.length > 0);
            State.haspaymentmethod = (Cart.paymentmethods.length > 0);
            State.paymentmethods = Cart.paymentmethods;

            if (Cart.shipaddress) State.shipaddress = (Cart.shipaddress.indexOf('null') === -1) ? Address[Cart.shipaddress] : false;
            if (Cart.billaddress) State.billaddress = (Cart.billaddress.indexOf('null') === -1) ? Address[Cart.billaddress] : false;
            if (Cart.shipmethod) State.shipmethod = Cart.shipmethod;

            angular.forEach(Cart.lines, function(obj, index) {
                if (obj.item) {
                    if (obj.item.custitem_noexpress) express = false;
                }

                try {
                    if (obj.item.itemid.length === 5) {
                        obj.item.sku = obj.item.itemid;
                    } else {
                        if (obj.item.custitem_sku.indexOf(':') !== -1) {
                            obj.item.sku = obj.item.custitem_sku.split(':')[1].trim();
                        } else {
                            obj.item.sku = obj.item.custitem_sku;
                        }
                    }
                } catch (err) {
                    console.error(err);
                }


                /*
                if (obj.options) {
                    angular.forEach(obj.options, function(obj, index) {
                        if (obj.id === "CUSTCOL122") express = false;
                    });
                }
                */

                if (State.shipaddress.zip) {
                    delivery.get_detailed_note(obj.item.itemid, State.shipaddress.zip, function(res) {
                        obj.ship_details = res;
                        obj.delivery_note = create_note(res, obj.item);
                    });
                } else {
                    delivery.get_note(obj.item.itemid, function(res) {
                        obj.ship_details = res;
                        obj.delivery_note = create_note(res, obj.item);
                    });
                }
            });

            State.expressIsAvailable = express;
            State.showCardForm = (Cart.shipaddress && Cart.billaddress && !State.haspaymentmethod && State.itemsincart) ? true : false;
            State.showShipMethod = (Cart.shipaddress && Cart.billaddress) ? true : false;

            if (!express && State.shipmethod === "2741") {
                State.shippingError = true;
            } else {
                State.shippingError = false;
            }

            function getNextStep() {
                if (!State.billaddress) return 'billaddress';
                if (!State.shipaddress) return 'shipaddress';
                if (!State.itemsincart) return 'itemsincart';
                if (!State.shipmethod) return 'shipmethod';
                if (!State.haspaymentmethod) return 'haspaymentmethod';
                if (Cart.confirmation) return 'confirmation';
                return 'readytosubmit';
            }

            State.nextStep = getNextStep();
            State.readytosubmit = (State.nextStep === 'readytosubmit');

            //console.log("|-- orderService updateCartInfo Complete -->");
        }

        function updateUserInfo() {
            if (DEV) console.log("|-- orderService updateUsernfo Called -->");
            try {
                data.ns.state.realemail = data.ns.user.email.indexOf('nomail.com') === -1;
            } catch (err) {
                console.log(err);
            }
        }

        function addToAddressObj(arr) {
            angular.forEach(arr, function(obj, ind) {
                data.ns.addressObj[obj.internalid] = obj;
            });
        }

        function updateCart(obj, callback, updateinfo) {
            if (DEV) console.log("|-- orderService updateCart Called -->");
            data.ns.cart = data.ns.cart || {};
            angular.forEach(obj, function(val, key) {
                data.ns.cart[key] = val;
                if (key === 'addresses') {
                    addToAddressObj(val);
                }
            });
            if (updateinfo) updateCartInfo();
            //console.log("|-- orderService updateCart Complete -->");
            return callback && callback();
        }

        function updateUser(obj, callback) {
            if (DEV) console.log("|-- orderService updateUser Called -->");
            data.ns.user = data.ns.user || {};
            angular.forEach(obj, function(val, key) {
                data.ns.user[key] = val;
            });
            updateUserInfo();
            //console.log("|-- orderService updateUser Complete -->");
            return callback && callback();
        }

        function updateAddress(arr, callback) {
            if (DEV) console.log("|-- orderService updateAddress Called -->");
            data.ns.address = data.ns.address || [];
            data.ns.address.length = arr.length;
            angular.forEach(arr, function(obj, ind) {
                data.ns.address[ind] = obj;
                data.ns.addressObj[obj.internalid] = obj;
            });
            //console.log("|-- orderService updateAddress Complete -->");
            return callback && callback();

        }

        function updateCreditCard(arr, callback) {
            if (DEV) console.log("|-- orderService updateCreditCard Called -->");
            data.ns.creditcard = data.ns.creditcard || [];
            data.ns.creditcard.length = arr.length;
            angular.forEach(arr, function(obj, ind) {
                data.ns.creditcard[ind] = obj;
            });
            //console.log("|-- orderService updateCreditCard Complete -->");
            return callback && callback();

        }

        function updateSettings(obj, callback) {
            if (DEV) console.log("|-- orderService updateSettings Called -->");
            data.ns.settings = data.ns.settings || {};
            angular.forEach(obj, function(val, key) {
                data.ns.settings[key] = val;
            });
            //console.log("|-- orderService updateSettings Complete -->");
            return callback && callback();

        }

        obj.getData = function() {
            if (DEV) console.log("|-- orderService getData Called -->");
            return data;
        };

        obj.getUserId = function() {
            if (DEV) console.log("|-- orderService getUserId Called -->");
            return data.ns.user.internalid;
        };

        obj.updateData = function(obj, callback) {
            if (DEV) console.log("|-- orderService updateData Called -->");
            angular.forEach(obj, function(val, key) {
                if (key === "cart") {
                    updateCart(val);
                } else if (key === "user") {
                    updateUser(val);
                } else if (key === "address") {
                    updateAddress(val);
                } else if (key === "creditcard") {
                    updateCreditCard(val);
                } else if (key === "settings") {
                    updateSettings(val);
                } else {
                    data.ns[key] = val;
                }
            });
            updateCartInfo();
            return callback && callback();
        };

        obj.addAddress = function(obj) {
            if (DEV) console.log("|-- orderService addAddress Called -->");
            var updated = false;
            data.ns.address = data.ns.address || [];
            angular.forEach(data.ns.address, function(address, key) {
                if (address.internalid === obj.internalid) {
                    data.ns.address[key] = obj;
                    updated = true;
                }
            });
            if (!updated) data.ns.address.push(obj);
            updateCartInfo();
        };

        obj.updateAddressList = function(obj) {
            if (DEV) console.log("|-- orderService updateAddressList Called -->");
            data.ns.address = obj.address;
        };

        obj.updateCart = function(obj, callback) {
            return updateCart(obj, callback, true);
        };

        obj.getPromocode = function() {
            if (DEV) console.log("|-- orderService getPromocode Called -->");
            return data.ns.cart.promocode;
        };

        obj.getPaymentMethods = function() {
            if (DEV) console.log("|-- orderService getPaymentMethods Called -->");
            return data.ns.settings.paymentmethods;
        };

        obj.getCart = function() {
            if (DEV) console.log("|-- orderService getCart Called -->");
            return data.ns.cart;
        };

        obj.focus = function(o) {
            if (DEV) console.log("|-- orderService focus Called -->");
            data.ns.focus = o;
        };

        obj.setNewAddress = function(type) {
            if (DEV) console.log("|-- orderService setNewAddress Called -->");
            data.ns.addMe = type;
            obj.focus({ type: 'address', action: 'new', data: type });
        };

        obj.closeNewAddress = function() {
            if (DEV) console.log("|-- orderService closeNewAddress Called -->");
            data.ns.addMe = false;
            obj.focus(false);
        };

        obj.setEditAddress = function(o) {
            if (DEV) console.log("|-- orderService setEditAddress Called -->");
            data.ns.editMe = o;
            obj.focus({ type: 'address', action: 'edit', data: o });
        };

        obj.removeEditAddress = function() {
            if (DEV) console.log("|-- orderService removeEditAddress Called -->");
            data.ns.editMe = false;
            obj.focus(false);
        };

        obj.addCCShow = function() {
            if (DEV) console.log("|-- orderService addCCShow Called -->");
            data.ns.addCC = true;
            obj.focus({ type: 'creditcard', action: 'new', data: false });
        };

        obj.addCCHide = function() {
            if (DEV) console.log("|-- orderService addCCHide Called -->");
            data.ns.addCC = false;
            obj.focus(false);
        };


        obj.addError = function(obj) {
            obj.ts = new Date().getTime().toString();
            if (angular.isObject(obj.errorMessage)) {
                angular.forEach(obj.errorMessage, function(val, key) {
                    var o = {};
                    o.ts = new Date().getTime().toString();
                    o.errorMessage = val;
                    o.from = obj;
                    data.ns.state.errors.push(o);
                });
            } else {
                data.ns.state.errors.push(obj);
            }

        };

        obj.removeError = function(err) {
            var errArr = data.ns.state.errors,
                length = errArr.length,
                i = 0,
                found = false;
            if (err.ts) {
                for (i; i < length && !found; i += 1) {
                    if (errArr[i].ts === err.ts) {
                        errArr.splice(i, 1);
                        found = true;
                    }
                }
            }
        };

        function audit_user_state() {
            if (DEV) console.log("|-- orderService audit_user_state Called -->");
            var ts = new Date().getTime();
            var max = ts - (60000 * 20);
            var has_login = !!tjbConfig.login;
            var login_valid = (has_login && tjbConfig.login_created && tjbConfig.login_created > max);
            return ((has_login && !login_valid) || !has_login);
        }

        function reset_local_storage() {
            if (DEV) console.log("|-- orderService reset_local_storage Called -->");
            angular.forEach(tjbConfig.lsKeys, function(val, key) {
                localStorage.removeItem(val);
            });
        }

        function logout_promise() {
            if (DEV) console.log("|-- orderService logout_promise Called -->");
            return $http.post(tjbConfig.apiRoot + 'logout.ss');
        }

        function login_promise() {
            if (DEV) console.log("|-- orderService login_promise Called -->");
            return $http({
                method: 'POST',
                url: tjbConfig.apiRoot + 'account-login.ss',
                data: JSON.stringify(tjbConfig.login),
                cache: false
            });
        }

        function init_promise() {
            if (DEV) console.log("|-- orderService init_promise Called -->");
            return $http.get(tjbConfig.apiRoot + 'init.ss');
        }

        obj.init = function() {
            if (DEV) console.log("|-- orderService init Called -->");

            var reset = audit_user_state();

            if (DEV) console.log("reset", reset);

            function start_clean() {
                if (DEV) console.log("|-- orderService start_clean Called -->");
                var defer = $q.defer();
                reset_local_storage();

                logout_promise().then(function(res) {
                    if (DEV) console.log("logout_promise res", res);
                    return init_promise();
                }).then(function(res) {
                    if (DEV) console.log("init_promise res", res);
                    obj.updateData(res.data);
                    return defer.resolve(obj.getData());
                }).catch(function(err) {
                    console.error(err);
                    return defer.reject(err);
                });

                return defer.promise;
            }

            function use_creds() {
                if (DEV) console.log("|-- orderService use_creds Called -->");
                var defer = $q.defer();
                init_promise().then(function(res) {
                    if (DEV) console.log("init_promise res", res);
                    return (res.data && res.data.user) ? res : login_promise();
                }).then(function(res) {
                    if (DEV) console.log("init_promise or login_promise", res);
                    obj.updateData(res.data);
                    return defer.resolve(obj.getData());
                }).catch(function(err) {
                    console.error(err);
                    return defer.reject(err);
                });

                return defer.promise;
            }

            return (reset) ? start_clean() : use_creds();
        };

        return obj;

    };

    angular.module('tjb').factory('tjbOrderService', ['tjbConfig', '$http', '$q', '$rootScope', 'deliveryService', tjbOrderService]);
}());
(function() {
    'use strict';
    var tjbAddressBlockCtrl = function(tjbOrderService) {
        var ctrl = this;
        ctrl.noEdit = false;

        ctrl.edit = function() {
            tjbOrderService.setEditAddress(ctrl.address);
        };

        ctrl.add = function() {
            tjbOrderService.setNewAddress(ctrl.type);
        };

        ctrl.$onInit = function() {
            ctrl.name = (ctrl.type === 'billaddress') ? 'Billing' : 'Shipping';
            ctrl.buttonText = ctrl.name + ' Address';
            ctrl.noEdit = ctrl.name === 'Shipping' && ctrl.cart.billaddress === ctrl.cart.shipaddress;
        };

        ctrl.$onChanges= function() {
            ctrl.noEdit = ctrl.name === 'Shipping' && ctrl.cart.billaddress === ctrl.cart.shipaddress;
        };

    };

    angular.module('tjb').component('tjbAddressBlock', {
        templateUrl: 'components/tjb-address/tjb-address-block.html',
        controller: ['tjbOrderService', tjbAddressBlockCtrl],
        controllerAs: '$ctrl',
        bindings: {
            user: '<',
            address: '<',
            type: '@',
            cart: '<'
        }
    });
}());
(function() {
    'use strict';
    var tjbAddressCtrl = function(tjbNsapi, tjbOrderService) {
        var ctrl = this;
        ctrl.showSameAsBilling = false;
        ctrl.processing = false;
        ctrl.addressType = '';

        ctrl.address = {};
        ctrl.country = {};
        ctrl.shipping = false;
        ctrl.billing = false;

        ctrl.closeAddress = function() {
            tjbOrderService.closeNewAddress();
        };

        ctrl.sameAsBilling = function() {
            ctrl.processing = true;
            var obj = {};
            obj.setShippingAddress = ctrl.state.billaddress.internalid;
            tjbNsapi.setAddress(obj, function(res) {
                ctrl.processing = false;
                ctrl.closeAddress();
            });
        };

        ctrl.submit = function() {
            ctrl.processing = true;
            ctrl.address.country = ctrl.country.selected.code;
            tjbNsapi.addAddress(ctrl.address, function(res) {
                ctrl.processing = false;
                ctrl.closeAddress();
            });
        };

        ctrl.$onInit = function() {

            if (ctrl.countries) {
                ctrl.country.selected = ctrl.countries.US;
            }

            if (ctrl.type === 'shipaddress') {
                ctrl.shipping = true;
                ctrl.addressType = 'Shipping';
                ctrl.address.defaultshipping = 'T';
                if (ctrl.state.billaddress) {
                    ctrl.showSameAsBilling = true;
                } else {
                    ctrl.showSameAsBilling = false;
                }
            } else if (ctrl.type === 'billaddress') {
                ctrl.billing = true;
                ctrl.addressType = 'Billing';
                ctrl.address.defaultbilling = 'T';
                ctrl.showSameAsBilling = false;
            }

            if (!ctrl.user) return;

            ctrl.address.fullname = ctrl.user.firstname + " " + ctrl.user.lastname;
            ctrl.address.phone = ctrl.user.phone;

        };

    };

    angular.module('tjb').component('tjbAddress', {
        templateUrl: 'components/tjb-address/tjb-address.html',
        controller: ['tjbNsapi', 'tjbOrderService', tjbAddressCtrl],
        bindings: {
            order: '<',
            type: '<',
            state: '<',
            countries: '<',
            user: '<'
        }
    });
}());
(function() {
    'use strict';
    var tjbBillingAddressCtrl = function(tjbNsapi, tjbOrderService) {
        var ctrl = this;
        ctrl.processing = false;

        ctrl.address = {};
        ctrl.country = {};
        ctrl.userData = {};

        ctrl.defaults = {};
        ctrl.defaults.defaultbilling = true;

        var letters = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]
        function getRandomInt(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min)) + min;
        }

        function createTempPassword(phoneNumber) {
            var rd1 = letters[getRandomInt(0, 26)];
            var rd2 = letters[getRandomInt(0, 26)];
            var rd3 = letters[getRandomInt(0, 26)];
            var rd4 = letters[getRandomInt(0, 26)];
            return 'tEMp$x' + rd1 + rd2 + rd3 + rd4 + phoneNumber.replace(/[^0-9]/g, '');
        }

        function createAccountObj(userData, tempPass) {
            var obj = {};
            angular.copy(userData, obj);
            obj.password = tempPass;
            obj.password2 = tempPass;
            if (!obj.email) {
                obj.email = 'notanemail@nomail.com';
                obj.emailsubscribe = 'T';
            } else {
                obj.emailsubscribe = 'T';
            }
            obj.confirm_email = obj.email;
            return obj;
        }

        ctrl.submit = function() {
            ctrl.processing = true;
            var tempPass = createTempPassword(ctrl.address.phone);
            var accountData = createAccountObj(ctrl.userData, tempPass);

            ctrl.address.country = ctrl.country.selected.code;
            ctrl.address.defaultbilling = 'T';
            ctrl.address.fullname = ctrl.userData.firstname + ' ' + ctrl.userData.lastname;

            try {
                localStorage.setItem('templogin', JSON.stringify({
                    email: accountData.email,
                    password: accountData.password,
                    redirect: ''
                }));
                localStorage.setItem('templogincreated', new Date().getTime().toString());

            } catch (err) {
                console.log(err);
            }


            tjbNsapi.register(accountData, function(obj) {
                tjbOrderService.updateData(obj);
                tjbNsapi.addAddress(ctrl.address, function() {
                    ctrl.processing = false;
                    tjbNsapi.getCart();
                });
                if (obj.user && obj.user.internalid) {
                    tjbNsapi.auditUser(obj.user.internalid, function(res) {
                        //console.log(res);
                    });
                }
            });
        };


        ctrl.$onInit = function() {

        };

        ctrl.$onChanges = function(obj) {
            if (ctrl.data && ctrl.data.settings && ctrl.data.settings.countries && !ctrl.address.country) {
                ctrl.country.selected = ctrl.data.settings.countries.US;
            }
        };
    };

    angular.module('tjb').component('tjbBillingAddress', {
        templateUrl: 'components/tjb-address/tjb-billing-address.html',
        controller: ['tjbNsapi', 'tjbOrderService', tjbBillingAddressCtrl],
        bindings: {
            data: '<'
        }
    });
}());
(function() {
    'use strict';
    var tjbUpdateAddressCtrl = function(tjbNsapi, tjbOrderService) {
        var ctrl = this;

        ctrl.processing = false;

        ctrl.address = {};
        ctrl.country = {};

        ctrl.closeAddress = function() {
            tjbOrderService.removeEditAddress();
        };

        ctrl.deleteAddress = function(obj) {
            ctrl.processing = true;
            tjbNsapi.removeAddress(obj, function(res) {
                ctrl.processing = false;
                ctrl.closeAddress();
            });
        };

        ctrl.submit = function() {
            ctrl.processing = true;
            tjbNsapi.updateAddress(ctrl.address, function() {
                ctrl.processing = false;
                ctrl.closeAddress();
            });
        };

        ctrl.$onInit = function() {
            ctrl.address = ctrl.editme;
            ctrl.country.selected = ctrl.countries[ctrl.editme.country];
        };
        
    };

    angular.module('tjb').component('tjbUpdateAddress', {
        templateUrl: 'components/tjb-address/tjb-update-address.html',
        controller: ['tjbNsapi', 'tjbOrderService', tjbUpdateAddressCtrl],
        bindings: {
            data: '<',
            editme: '<',
            countries:'<'
        }
    });
}());
(function() {
    'use strict';
    var tjbCartCtrl = function(tjbNsapi, tjbOrderService, $http, tjbConfig) {
        var ctrl = this;
        ctrl.hear = [{ "value": "3", "text": "Already purchased from M.LaHart" }, { "value": "7", "text": "Alumni newsletter" }, { "value": "4", "text": "Bookstore on campus" }, { "value": "1", "text": "M.LaHart catalog" }, { "value": "8", "text": "Friend" }, { "value": "11", "text": "Online search" }, { "value": "10", "text": "Alumni event" }, { "value": "5", "text": "Received a gift purchased at M.LaHart" }, { "value": "9", "text": "Sorority/Fraternity" }, { "value": "2", "text": "Magazine ad" }, { "value": "6", "text": "Web banner on another site" }, { "value": "13", "text": "Email" }];
        ctrl.processing = false;
        ctrl.showPromoCode = false;

        ctrl.catalog_code_text = tjbConfig.catalog_code_text;
        ctrl.how_did_you_hear_text = tjbConfig.how_did_you_hear_text;
        ctrl.how_did_you_hear_prefix = tjbConfig.how_did_you_hear_prefix;

        function revokeAccess(cb) {
            tjbNsapi.reset_all(function(res) {
                if(cb) cb(res);
            });
        }

        ctrl.newSession = function() {
            revokeAccess(function(res){
                if (tjbConfig.dev) console.log(res);
                window.location.reload();
            });
        };

        ctrl.addMentionCode = function(val) {
            if (!val || val.length === 0) return;
            ctrl.processing = true;
            if (tjbConfig.dev) console.log(val);
            tjbNsapi.updateOrderCustomFields({ 'custbodysource_code': val }, function(res) {
                ctrl.processing = false;
                if (tjbConfig.dev) console.log(res);
            });
        };

        ctrl.addHearAboutUs = function(val) {
            if (!val || val.length === 0) return;
            ctrl.processing = true;
            if (tjbConfig.dev) console.log(val);
            tjbNsapi.updateOrderCustomFields({ 'custbodyhearabout': val }, function(res) {
                ctrl.processing = false;
                if (tjbConfig.dev) console.log(res);
            });
        };

        ctrl.addNewAddress = function(type) {
            tjbOrderService.setNewAddress(type);
        };

        ctrl.deleteCustomField = function(obj) {
            ctrl.processing = true;
            tjbNsapi.updateOrderCustomFields(obj, function(res) {
                ctrl.processing = false;
            });
        };

        function changeEmail(email, callback) {
            var creds = JSON.parse(localStorage.getItem(tjbConfig.lsKeys.login));
            var obj = {};
            obj.action = 'changeemail';
            obj.password = creds.password;
            obj.new_email = email;
            tjbNsapi.tjbx(obj, function(res) {
                if (res.errorMessage) {
                    callback();
                    return console.log(res);
                } else {
                    callback();
                    return console.log(res);
                }
            });
        }

        ctrl.removeFromCart = function(id) {
            if (tjbConfig.dev) console.log("|-- removeFromCart Called -->");
            tjbNsapi.removeFromCart({
                "action": "update",
                "info": {
                    'orderitemid': id,
                    'quantity': 0
                }
            }, function(res) {
                if (tjbConfig.dev) console.log("|-- removeFromCart Complete -->");
                //console.log(res);
            });
        };

        ctrl.updateLine = function(line) {
            ctrl.processing = true;
            if (tjbConfig.dev) console.log("|-- updateLine Called -->");
            var obj = {
                "item": {
                    "internalid": line.item.internalid
                },
                "quantity": line.quantity,
                "internalid": line.internalid,
                "options": {},
                "type": line.itemtype,
                "splitquantity": null
            };
            if (line.options) {
                angular.forEach(line.options, function(val, key) {
                    obj.options[val.id.toLowerCase()] = val.value;
                });
            }
            tjbNsapi.updateItem(obj, line.internalid, function(res) {
                if (tjbConfig.dev) console.log("|-- updateLine Complete -->");
                ctrl.processing = false;
            });

        };

        function afterSubmit() {
            var obj = {};
            if (ctrl.errorMsg) {
                obj.soerror = true;
                obj.soid = ctrl.confirmation.internalid;
                obj.somsg = ctrl.errorMsg;
            }

            if (obj.soerror) {
                tjbNsapi.tjbx(obj, function(res) {
                    console.log(res);
                });
            }
        }

        function processError(errorMsg) {
            var msg = [];
            msg.push(errorMsg);
            try {
                angular.forEach(ctrl.data.cart.paymentmethods[0].creditcard, function(val, key) {
                    if (key === 'paymentmethod') return;
                    msg.push(key + ": " + val);
                });
            } catch (err) {
                console.log(err);
            }
            return msg.join('\r\n');
        }

        function submitOrder() {
            tjbNsapi.submitOrder(function(res) {
                if (res.errorMessage) {
                    ctrl.errorMsg = processError(res.errorMessage);
                    tjbNsapi.submitAsError(function(res) {
                        ctrl.processing = false;
                        ctrl.confirmation = res.confirmation;
                        afterSubmit();
                    });
                } else {
                    ctrl.processing = false;
                    ctrl.confirmation = res.confirmation;
                    revokeAccess();
                    afterSubmit();
                }
            });
        }

        function addRealEmail(email) {
            changeEmail(email, function() {
                submitOrder();
            });
        }

        ctrl.submitOrder = function() {
            ctrl.processing = true;
            ctrl.errorMsg = false;
            try {
                if (ctrl.emailForm.email.$viewValue && ctrl.emailForm.email.$valid) return addRealEmail(ctrl.emailForm.email.$viewValue);
                return submitOrder();
            } catch (err) {
                return submitOrder();
            }
        };

        ctrl.$onInit = function() {
            ctrl.data = tjbOrderService.getData().ns;
            //console.log('ctrl.data', ctrl.data);
        };
    };

    angular.module('tjb').component('tjbCart', {
        templateUrl: 'components/tjb-cart/tjb-cart.html',
        controller: ['tjbNsapi', 'tjbOrderService', '$http', 'tjbConfig', tjbCartCtrl],
        controllerAs: '$ctrl'
    });
}());
(function() {
    'use strict';
    var availablemethods = false;

    function paymenthodIdCreditCart(cc_number) {
        var cardPatterns = {
                'VISA': /^4[0-9]{12}(?:[0-9]{3})?$/,
                'Master Card': /^5[1-5][0-9]{14}$/,
                'American Express': /^3[47][0-9]{13}$/,
                'Discover': /^6(?:011|5[0-9]{2})[0-9]{12}$/,
                'Maestro': /^(?:5[0678]\d\d|6304|6390|67\d\d)\d{8,15}$/
            },
            cardName,
            paymentmethod = false;
        for (var n in cardPatterns) {
            if (cardPatterns[n].test(cc_number)) cardName = n;
        }
        for (var i = 0; i < availablemethods.length && !paymentmethod; i += 1) {
            if (cardName === availablemethods[i].name) {
                paymentmethod = availablemethods[i];
            }
        }
        return paymentmethod;
    }

    function addCCObj(data) {
        return {
            "paymentmethod": data.paymentmethod.internalid,
            "ccnumber": data.ccnumber,
            "expmonth": data.expmonth,
            "expyear": data.expyear,
            "ccname": data.ccname,
            "ccdefault": 'T',
            "savecard": 'T',
            "authcode": ctrl.ccsecuritycode
        };
    }

    var tjbCreditCardCtrl = function(tjbNsapi, tjbOrderService) {
        var ctrl = this;        
        ctrl.processing = false;

        ctrl.ccsecPattern = /^-?(?:\d+|\d{1,3}(?:,\d{3})+)(?:\.\d+)?$/;

        ctrl.availablemethods = [];
        ctrl.ccsecuritycode = '';

        ctrl.formData = {};
        ctrl.formData.paymentmethod = {};


        ctrl.checkNumber = function(num) {
            availablemethods = availablemethods || tjbOrderService.getPaymentMethods();
            ctrl.formData.paymentmethod = paymenthodIdCreditCart(num);
        };

        function createPaymentObj(data) {
            return {
                "type": "creditcard",
                "creditcard": {
                    "debitcardissueno": null,
                    "validfrommon": null,
                    "expyear": data.expyear,
                    "validfromyear": null,
                    "ccsecuritycode": ctrl.ccsecuritycode,
                    "savecard": "T",
                    "internalid": null,
                    "expmonth": data.expmonth,
                    "customercode": null,
                    "validfrom": null,
                    "ccname": data.ccname,
                    "ccdefault": "T",
                    "paymentmethod": data.paymentmethod,
                    "ccnumber": data.ccnumber
                },
                "primary": true
            };
        }

        ctrl.submitCard = function() {
            var payObj = createPaymentObj(ctrl.formData);

            var obj = {
                paymentmethods: []
            };

            obj.paymentmethods[0] = payObj;

            tjbNsapi.setPaymentMethods(obj, function(res) {
                ctrl.processing = false;
                if(res.errorMessage) return;
                tjbOrderService.addCCHide();
                console.log(res);
            });

        };

        ctrl.removeCard = function() {
            ctrl.processing = true;
            tjbNsapi.removePaymentMethods(function(res) {
                console.log(res);
                ctrl.processing = false;
            });
        };

        ctrl.close = function() {
            tjbOrderService.addCCHide();
        };

        ctrl.$onInit = function() {
            ctrl.data = tjbOrderService.getData();
            ctrl.availablemethods = ctrl.data.ns.settings.paymentmethods;
        };
    };

    angular.module('tjb').component('tjbCreditCard', {
        templateUrl: 'components/tjb-creditcard/tjb-creditcard.html',
        controller: ['tjbNsapi', 'tjbOrderService', tjbCreditCardCtrl],
        controllerAs: '$ctrl'
    });
}());
(function() {
    'use strict';
    var tjbCustomerCtrl = function($http, tjbConfig, tjbNsapi) {
        var ctrl = this;

        ctrl.edit = function() {            

        };


        ctrl.$onInit = function() {

            
        };

        ctrl.$onChanges = function(obj) {
            
        };
    };

    angular.module('tjb').component('tjbCustomerBlock', {
        templateUrl: 'components/tjb-customer/tjb-customer-block.html',
        controller: ['$http', 'tjbConfig','tjbNsapi', tjbCustomerCtrl],
        controllerAs: '$ctrl',
        bindings: {
            user: '<',
            state: '<'
        }
    });
}());
/*
addAddress(address)
addCreditCard(creditcard)
emailCustomer(subject, body)
getAddress(addressid, fields)
getAddressBook(fields)
getCampaignSubscriptions(fields)
getCampaignSubscriptions(subscriptionId,fields)
getCreditCard(creditcardid, fields)
getCreditCards(fields)
getCustomFields()
getCustomFieldValues()
getFieldValues(fields)
isGuest()
removeAddress(addressid)
removeCreditCard(creditcardid)
setLoginCredentials(customer)
updateAddress(address)
updateCampaignSubscriptions(subscriptions)
updateCreditCard(creditcard)
updateProfile(customer)
 */
(function() {
    'use strict';
    var tjbErrorsCtrl = function(tjbOrderService) {

        var ctrl = this;

        ctrl.removeError = function(error) {
            tjbOrderService.removeError(error);
        };

        ctrl.$onChanges = function(obj) {

        };

        ctrl.$onInit = function(obj) {

        };
    };

    angular.module('tjb').component('tjbErrors', {
        templateUrl: 'components/tjb-errors/tjb-errors.html',
        controller: ['tjbOrderService', tjbErrorsCtrl],
        controllerAs: '$ctrl',
        bindings: {
            errors: '<'
        }
    });
}());
(function() {
    'use strict';
    angular.module('tjb').component('tjbHelp', {
        templateUrl: 'components/tjb-help/tjb-help.html',
        controller: [function() {
            var ctrl = this;
            ctrl.open = false;
        }],
        controllerAs: '$ctrl',
        bindings: {
            helpText: '<'
        }
    });
}());
(function() {
    'use strict';
    var tjbLogin = function(tjbNsapi) {
        var ctrl = this;
        var tempPass = 'tEMp$x44';
        var tempEmail = 'timothyjblevins@gmail.com';
        ctrl.formData = {};

        ctrl.login = function() {
            ctrl.formData.password = tempPass;
            ctrl.formData.email = tempEmail;
            ctrl.formData.redirect = '';
            tjbNsapi.accountLogin(ctrl.formData, function(res) {
                console.log(res);
            });
        };

        ctrl.$onInit = function() {

        };
    };

    angular.module('tjb').component('tjbLogin', {
        templateUrl: 'components/tjb-login/tjb-login.html',
        controller: ['tjbNsapi', tjbLogin]
    });
}());


(function() {
    'use strict';

    var tjbPaymentBlockCtrl = function(tjbNsapi, tjbOrderService) {
        var ctrl = this;
        ctrl.processing = false;

        ctrl.addPaymentMethod = function() {
            //console.log("|-- tjbPaymentBlockCtrl addPaymentMethod Called -->");
            tjbOrderService.addCCShow();
        };

        ctrl.removeCard = function() {
            //console.log("|-- tjbPaymentBlockCtrl removeCard Called -->");
            ctrl.processing = true;
            tjbNsapi.removePaymentMethods(function(res) {
                //console.log(res);
                ctrl.processing = false;
            });
        };


        ctrl.$onInit = function() {};
        ctrl.$onChanges = function() {};
    };

    angular.module('tjb').component('tjbPaymentBlock', {
        templateUrl: 'components/tjb-payment-information/tjb-payment-block.html',
        controller: ['tjbNsapi', 'tjbOrderService', tjbPaymentBlockCtrl],
        controllerAs: '$ctrl',
        bindings: {
            methods: '<'
        }
    });
}());
(function() {
    "use strict";
    var deliveryService = function(root, $http) {
        var obj = {};

        var cache_obj = {};

        obj.get_note = function (sku, callback) {
            var params = 'sku=' + sku;

            if (cache_obj[params] && cache_obj[params].expires && (new Date().getTime() < cache_obj[params].expires)) {
                cache_obj[params].from_cache = true;
                return callback(cache_obj[params]);
            } else {
                $http.get(root.delivery_information + '?' + params).then(function(res) {
                    //console.log('res', res);

                    if (!res || !res.data || !res.data.data || !res.data.note) return callback(false);

                    cache_obj[params] = res.data;

                    callback(cache_obj[params]);

                }, function(err) {
                    console.log('err', err);
                    return callback(false);
                });
            }

        };

        obj.get_detailed_note = function (sku, postal_code, callback) {
            var params = 'sku=' + sku + '&postalcode=' + postal_code;

            if (cache_obj[params] && cache_obj[params].expires && (new Date().getTime() < cache_obj[params].expires)) {
                cache_obj[params].from_cache = true;
                return callback(cache_obj[params]);
            } else {
                $http.get(root.delivery_information + '?' + params).then(function(res) {
                    //console.log('res', res);

                    if (!res || !res.data || !res.data.data || !res.data.note) return callback(false);

                    cache_obj[params] = res.data;

                    callback(cache_obj[params]);

                }, function(err) {
                    console.log('err', err);
                    return callback(false);
                });
            }

        };


        return obj;
    };

    angular.module('tjb').factory('deliveryService', ['tjbConfig', '$http', deliveryService]);
}());
(function() {
    'use strict';
    var tjbProductDetailCtrl = function($sce, root, tjbItemService, tjbNsapi, delivery, $timeout, optionService) {

        var getting_delivery_note = false;
        var ctrl = this;

        ctrl.qty = 1;
        ctrl.internalid = false;
        ctrl.giftA = false;

        ctrl.processing = false;

        ctrl.formData = {};

        ctrl.matrix_option = false;
        ctrl.matrix_option_selected = false;

        ctrl.price = '';
        ctrl.name = '';
        ctrl.sku = '';

        ctrl.options = false;

        ctrl.optionsPath = 'components/tjb-products/tjb-product-detail-options.html';
        ctrl.desc = 'open';
        ctrl.shipping = 'open';
        ctrl.personalization = 'open';

        ctrl.active_note = {};

        function update_with_changes() {
            if (ctrl.matrix_option_selected) {
                ctrl.price = '$' + ctrl.matrix_option_selected.price;
                ctrl.name = ctrl.matrix_option_selected.name;
                ctrl.sku = ctrl.matrix_option_selected.sku;
            } else {
                ctrl.price = '$' + ctrl.product.price;
                ctrl.name = ctrl.product.name;
                ctrl.sku = ctrl.product.sku;
            }

            get_delivery(ctrl.sku);
        }

        ctrl.selectChange = function(name) {

            update_with_changes();

            if (name === 'internalid') {
                ctrl.formData.internalid = ctrl.matrix_option_selected.value;
            }
        };


        ctrl.addToCart = function(isvalid) {
            if (!isvalid) return;

            ctrl.processing = true;

            var obj = {
                "action": "add",
                "info": {
                    "quantity": ctrl.qty,
                    "internalid": ctrl.product.internalid.toString(),
                    "options": {}
                }
            };

            angular.forEach(ctrl.formData, function(val, key) {
                if (key === 'internalid') {
                    obj.info.internalid = val.toString();
                } else {
                    obj.info.options[key] = val;
                }
            });

            tjbNsapi.addToCart(obj, function(res) {
                //console.log(res);
                ctrl.processing = false;
                tjbItemService.removeFeatured();
            });
        };


        function create_dynamic_note(delivery_obj) {
            ctrl.active_note.type = 'realtime';

            ctrl.active_note.standard = delivery_obj.data.standard || false;
            ctrl.active_note.express = delivery_obj.data.express || false;
            ctrl.active_note.express_available = delivery_obj.data.express_available || false;
            ctrl.active_note.international = delivery_obj.data.international || false;
            ctrl.active_note.insurance = delivery_obj.data.insurance || false;

            //ctrl.active_note.supplement = ctrl.item && ctrl.item.shipItem && ctrl.item.deliveryNoteShippingSupplement || false;

            ctrl.active_note.handling = delivery_obj.data.handling || false;
            ctrl.active_note.arrives = delivery_obj.arrives || false;

        }

        function update_using_live(delivery_obj) {
            if (delivery_obj.out_of_stock) {

                // out of stock overrides everything
                ctrl.active_note.type = 'outofstock';
                ctrl.active_note.html_note = $sce.trustAsHtml(delivery_obj.note);

            } else if (delivery_obj.date_driven) {

                // date driven notes override dynamic
                ctrl.active_note.type = 'datedriven';
                ctrl.active_note.html_note = $sce.trustAsHtml(delivery_obj.note);

            } else {

                try {
                    // process live note
                    create_dynamic_note(delivery_obj);
                } catch (err) {
                    ctrl.active_note.type = 'error';
                    ctrl.delivery = 'Delivery Information Currently Unavailable';
                }
            }
        }


        function get_delivery(sku) {
            if (getting_delivery_note) return;
            getting_delivery_note = true;
            delivery.get_note(sku, function(delivery_note) {
                //console.log('delivery_note', delivery_note);
                if (delivery_note.note) {
                    update_using_live(delivery_note);
                } else {
                    ctrl.active_note.type = 'error';
                    ctrl.delivery = 'Delivery Information Currently Unavailable';
                }

                getting_delivery_note = false;
            });
        }

        ctrl.toggleView = function(view) {
            ctrl[view] = (ctrl[view] === 'closed') ? 'open' : 'closed';
        };

        ctrl.removeFeatured = function() {
            tjbItemService.removeFeatured();
        };

        function update_matrix_label_with_price() {
            var matrix_price = 0;
            var matrix_price_varies = false;

            angular.forEach(ctrl.matrix_option.options, function(obj, index) {
                var price = obj.price;
                if (!matrix_price) {
                    matrix_price = price;
                } else if (price !== matrix_price) {
                    matrix_price_varies = true;
                }
            });

            if (matrix_price_varies) {
                angular.forEach(ctrl.matrix_option.options, function(obj, index) {
                    obj.label = obj.label + ' - $' + obj.price;
                });
            }
            return matrix_price_varies;
        }

        ctrl.$onInit = function() {
            if (!ctrl.product) return;

            //console.log('ctrl.product', ctrl.product);

            if (ctrl.product.options) {
                angular.forEach(ctrl.product.options, function(name, index) {
                    if (typeof name === 'string') {
                        ctrl.product.options[index] = optionService.get_option(name);
                    }
                });
            }

            var options = [];

            if (ctrl.product.matrix) {
                ctrl.matrix_option = ctrl.product.matrix;

                if (ctrl.product.VARIANT) {
                    angular.forEach(ctrl.matrix_option.options, function(obj, index) {
                        if (obj.sku === ctrl.product.VARIANT) {
                            ctrl.matrix_option_selected = obj;
                        }
                    });
                }

                if (!ctrl.matrix_option_selected) {
                    ctrl.matrix_option_selected = ctrl.matrix_option.options[0];
                }

                ctrl.formData.internalid = ctrl.matrix_option_selected.value;

                update_matrix_label_with_price();
            }

            angular.forEach(ctrl.product.options, function(obj, index) {
                options.push(obj);
            });

            if (options.length > 0) ctrl.options = options;

            ctrl.loading = false;

            if (ctrl.product && ctrl.product.filters && ctrl.product.filters.shop_type && ctrl.product.filters.shop_type.indexOf('Nascar') !== -1) {
                ctrl.base_url = 'https://www.gotovictorylane.com/';
            } else {
                ctrl.base_url = 'https://www.mlahart.com/';
            }

            update_with_changes();

            //console.log('ctrl.$onInit - ctrl.product', ctrl.product);
            //console.log('ctrl.$onInit - ctrl.options', ctrl.options);
            //console.log('ctrl.$onInit - ctrl.formData', ctrl.formData);
            //console.log('ctrl.$onInit - ctrl.matrix_option_selected', ctrl.matrix_option_selected);
        };
    };

    angular.module('tjb').component('tjbProductDetail', {
        templateUrl: 'components/tjb-products/tjb-product-detail.html',
        controller: ['$sce', 'tjbConfig', 'tjbItemService', 'tjbNsapi', 'deliveryService', '$timeout', 'optionService', tjbProductDetailCtrl],
        controllerAs: '$ctrl',
        bindings: {
            product: '<'
        }
    });
}());
(function() {
    'use strict';
    var tjbProgressCtrl = function(tjbOrderService) {

        var ctrl = this;

        ctrl.$onChanges = function(obj) {

        };

        ctrl.$onInit = function(obj) {

        };
    };

    angular.module('tjb').component('tjbProgress', {
        templateUrl: 'components/tjb-progress/tjb-progress.html',
        controller: ['tjbOrderService', tjbProgressCtrl],
        controllerAs: '$ctrl',
        bindings: {
            state: '<'
        }
    });
}());
(function() {
    'use strict';
    var tjbPromocodeCtrl = function(tjbConfig, tjbNsapi, tjbOrderService) {
        var ctrl = this;

        ctrl.promocode = "";
        ctrl.promoError = false;
        ctrl.helpText = tjbConfig.promoText;
        ctrl.processing = false;

        function promoSuccess() {
            ctrl.promoError = false;
        }

        function promoError(res) {
            ctrl.promoError = ctrl.promocode;
            ctrl.promocode = "";
            tjbNsapi.sync(function(res) {
                console.log(res);
            });
        }

        function hasChanged() {
            try {
                if (ctrl.data.ns.cart.promocode.code === ctrl.promocode) return false;
            } catch (err) {
                if (ctrl.promocode.length === 0) return false;
            }
            return true;
        }

        function promoObj(code) {
            return {
                promocode: {
                    code: code || ''
                }
            };
        }

        ctrl.updatePromo = function() {
            if (hasChanged()) {
                ctrl.processing = true;
                tjbNsapi.updatePromo(promoObj(ctrl.promocode), function(res) {
                    ctrl.processing = false;
                    return (res.errorMessage) ? promoError(res) : promoSuccess();
                });
            }
        };

        ctrl.removePromo = function() {
            ctrl.processing = true;
            tjbNsapi.deletePromo(function(res) {
                ctrl.processing = false;
                return (res.errorMessage) ? promoError(res) : promoSuccess();
            });
        };

        ctrl.$onInit = function() {
            ctrl.data = tjbOrderService.getData();
        };
    };

    angular.module('tjb').component('tjbPromocode', {
        templateUrl: 'components/tjb-promocode/tjb-promocode.html',
        controller: ['tjbConfig', 'tjbNsapi', 'tjbOrderService', tjbPromocodeCtrl],
        controllerAs: '$ctrl'
    });
}());

(function() {
    'use strict';
    var tjbRegisterAsGuestCtrl = function(tjbNsapi) {
        var ctrl = this;
        var tempPass = 'tEMp$x44';

        ctrl.customer = {};

        ctrl.submit = function(isvalid) {
            if (!isvalid) return;
            ctrl.customer.password = tempPass;
            ctrl.customer.password2 = tempPass;
            ctrl.customer.passwordhint = 'Temporary Password';
            ctrl.customer.confirm_email = ctrl.customer.email;
            tjbNsapi.accountRegister(ctrl.customer, function(res) {
                console.log(res);
            });
        };

        ctrl.$onInit = function() {

        };
    };

    angular.module('tjb').component('tjbRegisterAsGuest', {
        templateUrl: 'components/tjb-register/tjb-register-as-guest.html',
        controller: ['tjbNsapi', tjbRegisterAsGuestCtrl]
    });
}());
/*
    registerCustomer(customer)

    firstname [required] {String}
    lastname [required] {String}
    companyname [optional] {String}
    email [required] {String}
    password [required] {String}
    password2 [required] {String}
    passwordhint [optional] {String}
    emailsubscribe [optional] {String}
    leadsource [optional] {String}

    sendPasswordRetrievalEmail(customeremail)


 */

(function() {
    'use strict';
    var tjbSearchBoxNsCtrl = function(tjbItemService, tjbNsapi, $timeout) {
        var ctrl = this;

        ctrl.item_list = [];
        ctrl.shop = false;
        ctrl.search_active = false;
        ctrl.view = '';

        ctrl.set_view = function(type) {
            ctrl.view = (type === 'list') ? 'horizontal': '';
        };

        ctrl.make_search_active = function() {
            ctrl.search_active = true;
        };

        ctrl.make_search_inactive = function() {
            ctrl.search_active = false;
        };

        ctrl.selectKit = function(item) {
            //console.log('item', item);
            var sku = item.meta.netsuite.parent_skux || item.meta.netsuite.skux;

            var variant = (item.meta.netsuite.parent_skux) ? item.meta.netsuite.skux: false;         

            tjbItemService.featureKitSKU(sku, variant);

            //tjbItemService.featureKit(item.meta.netsuite.rec_id);

            ctrl.make_search_inactive();
        };

        ctrl.update_items = function(item_list) {
            $timeout(function() {
                ctrl.item_list = item_list;
            });
        };

        ctrl.update_shop = function(shop) {
            $timeout(function() {
                ctrl.shop = shop;
            });
        };


        function algolia_init() {
            var search_instance;


            var _CONFIG = {
                section_id: 'order_entry',
                placeholder: 'Search Item, SKU or Brand',
                results_per_page: 40,
                distinct: true,
                search_school_first: true,
                school_placeholder: 'Search for the School',
                show_gender: true,
                page_title: 'Order Entry Search',
                gac_threshold: 500
            };


            function init(_CONFIG) {

                var _client = algoliasearch('XO6QV027C6', '12c2bf2cb6f3a4ce0737bb51901af11b');
                var _algolia_search = false;
                var _search = false;
                var _AC_SHOP = false;
                var autocomplete = false;
                var getAlgoliaResults = false;
                var shop_name_attr_path = 'meta.global.shop_name';
                var gender_attr_path = 'meta.global.gender';
                var item_template_id = '#hit-template';
                var item_class = 'search_item';
                var jsonld_el = document.getElementById('search_jsonld');
                var loading = 'lazy';

                var _config = {
                    "searchBox": {
                        "container": "#lp_search_input",
                        "placeholder": _CONFIG.placeholder,
                        "showSubmit": true,
                        "showReset": false,
                        "cssClasses": {
                            "root": "",
                            "input": ["search_input"],
                            "reset": "search_btn_reset",
                            "submit": "search_btn_submit"
                        }
                    },
                    "infiniteHits": {
                        "container": "#lp_search_results",
                        "templates": {
                            "empty": function(results, o) {
                                console.log('results', results);

                                var shop = false;
                                var query = (results.query && results.query !== "") ? results.query : false;

                                try {
                                    shop = results._state.hierarchicalFacetsRefinements["meta.global.shop_name"][0];
                                } catch (err) {

                                }

                                if (shop) {
                                    if (query) {
                                        return o.html(['No results for <b>' + query + '</b> in the <b>' + shop + '</b> collection']);
                                    } else {
                                        return o.html(['No results in the <b>$' + shop + '</b> collection']);
                                    }
                                } else {
                                    return o.html(['No results for <b>' + query + '</b>']);
                                }
                            },
                            "item": function(results, o) {
                                //console.log('results', results);
                                //console.log('o', o);
                                //  ${(results.on_sale) ? 'sale_item': ''} ${(results.made_in_usa) ? 'usa_item': ''} ${results.meta.item.delivery_class}
                                /*

                                function get_price_html() {
                                    if (results.on_sale) {
                                        return '<div class="search_item_price">' + results.compare_at_price + '</div>' +
                                            '<div class="search_item_price final_price">' + results.price + '</div>' +
                                            '<div class="search_item_action">SALE <i class="fa fa-caret-right"></i></div>';
                                    } else {
                                        return '<div class="search_item_price">' + results.price + '</div>' +
                                            '<div class="search_item_action">Details&nbsp;&nbsp;<i class="fa fa-caret-right"></i></div>';
                                    }
                                }

                                return html `
                                <div class="search_item_link ICOSM">
                                    <img class="search_item_image" alt="${ results.title }" width="200" height="200" src="${ results.image }&width=200" loading="${ results.loading }">     
                                    <h3 class="search_item_title">${ results.title }</h3> 
                                    <div>
                                        <div class="search_item_shop_icon"><span class="I ${results.meta.global.lpcode}"></span></div>
                                    </div>
                                    <div class="delivery_message">
                                        <div class="holiday_message"><span>${results.holiday_note}</span></div>
                                    </div>
                                </div>
                                `;
                                */


                            } //document.querySelector(item_template_id).textContent
                        },
                        "cssClasses": {
                            "root": "tC",
                            "empty": "empty",
                            "item": item_class,
                            "list": "si_list cf",
                            "loadMore": "search_show_more",
                            "disabledLoadMore": "search_show_more_disabled"
                        }
                    },
                    "configure": {
                        "hitsPerPage": _CONFIG.results_per_page,
                        "distinct": _CONFIG.distinct
                    },
                    "gender_menu": {
                        "container": "#lp_gender_menu",
                        "attribute": 'meta.global.gender',
                        "templates": {
                            "item": function(o) {
                                return '<a class="' + o.cssClasses.link + '" href="' + o.url + '"><span class="' + o.cssClasses.label + '">' + o.label + '</span></a>';
                            }
                        },
                        "sortBy": ["name:desc"],
                        "cssClasses": {
                            "root": "bp_gen",
                            "list": ["bp_gen_list"],
                            "item": ["bp_gen_list_item"],
                            "selectedItem": "bp_gen_list_item_selected",
                            "label": "bp_gen_list_item_label",
                            "link": "bp_gen_list_item_link"
                        }
                    }
                };

                function check_combine_by_name(item, prefix_arr){
                    if(!item.meta || !item.meta.netsuite || !item.meta.netsuite.stock_description) return false;

                    var stock = item.meta.netsuite.stock_description;

                    var combine = false;

                    prefix_arr.forEach(function(prefix){
                        if(stock.indexOf(prefix) !== -1) combine = true;
                    });

                    return combine;
                }


                function transform_items(items) {

                    var unique_title = {};

                    var keep = [];
                    var count = 0;

                    items.forEach(function(item, i) {

                        var combine_by_name = check_combine_by_name(item, ['SweTAY', 'RingJH', 'GlaML']);

                        if(combine_by_name){
                            if(unique_title[item.title]) return;
                            unique_title[item.title] = true;
                            item.hide_variant_title = true;
                        }

                        item.loading = loading;

                        if (item.compare_at_price > item.price) {
                            item.on_sale = true;
                            item.compare_at_price = '$' + parseFloat(item.compare_at_price).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        }
                        item.price = '$' + parseFloat(item.price).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

                        if (item.tags.indexOf('USA') !== -1) {
                            item.made_in_usa = true;
                        }
                        if(count > 19) return;
                        count += 1;

                        keep.push(item);

                    });

                    //console.log('keep', keep);

                    ctrl.update_items(keep);

                    return keep;
                }

                function state_to_route(data_in) {
                    //console.log('state_to_route - data_in', data_in);
                    var _gender_map = {
                        '-male': 'women',
                        '-female': 'men'
                    };

                    var data_out = {};
                    var _state = data_in.shopify_products;

                    if (_state.query) {
                        data_out.query = _state.query;
                    }

                    if (_state.menu) {

                        if (_state.menu[gender_attr_path] && _gender_map[_state.menu[gender_attr_path]]) {
                            data_out.for = _gender_map[_state.menu[gender_attr_path]];
                        }

                        if (_state.menu[shop_name_attr_path]) {
                            data_out.shop = _state.menu[shop_name_attr_path];
                        }
                    }

                    //console.log('state_to_route - data_out', data_out);

                    return data_out;
                }

                function route_to_state(data_in) {
                    //console.log('route_to_state - data_in', data_in);

                    var _gender_map = {
                        'women': '-male',
                        'men': '-female'
                    };

                    var data_out = {};

                    var index_obj = {
                        menu: {},
                        toggle: {}
                    };

                    if (data_in.query) {
                        index_obj.query = data_in.query;
                    }

                    if (data_in.for && _gender_map[data_in.for]) {
                        index_obj.menu[gender_attr_path] = _gender_map[data_in.for];
                    }

                    if (data_in.shop) {
                        index_obj.menu[shop_name_attr_path] = data_in.shop;
                    }

                    if (data_in.sale) {
                        index_obj.toggle.on_sale = data_in.sale;
                    }


                    data_out = { shopify_products: index_obj };

                    //console.log('route_to_state - data_out', data_out);

                    return data_out;
                }

                var routing = {
                    stateMapping: {
                        stateToRoute: state_to_route,
                        routeToState: route_to_state
                    }
                };

                function ShopUi(_helper) {
                    var helper = _helper;

                    ctrl.active_el_class = '';    

                    var _active_shop = false;

                    function get_shop_name() {
                        var _shop = helper.state.getHierarchicalRefinement(shop_name_attr_path);
                        return _shop[0] || false;
                    }

                    function updateUI(name) {

                        ctrl.active_el_class = 'has_shop';

                        _active_shop = name;

                        ctrl.update_shop(name);

                        helper.search();

                    }

                    function removeUI() {

                        ctrl.active_el_class = '';

                        _active_shop = false;

                        ctrl.update_shop(false);
                    }

                    function uiSync() {
                        var shop_name = get_shop_name();

                        if (shop_name) {
                            if (shop_name === _active_shop) return;

                            updateUI(shop_name);
                        } else {
                            if (!_active_shop) return;

                            removeUI();
                        }
                    }

                    function remove_active_shop() {
                        if (helper.hasRefinements(shop_name_attr_path)) {
                            helper.clearRefinements(shop_name_attr_path);                    
                            helper.search();
                        }
                        uiSync();
                    }

                    ctrl.remove_shop = remove_active_shop;

                    function update_shop(name) {
                        if (!name || name === '') return remove_active_shop();

                        var shop_name = get_shop_name();

                        if (shop_name && shop_name !== name) {
                            helper.clearRefinements(shop_name_attr_path);
                        }

                        helper.addHierarchicalFacetRefinement(shop_name_attr_path, name);

                        helper.search();

                        uiSync();
                    }

                    uiSync();

                    return {
                        uiSync: uiSync,
                        update_shop: update_shop
                    };
                }


                function add_autocomplete() {
                    autocomplete = window['@algolia/autocomplete-js'].autocomplete;
                    getAlgoliaResults = window['@algolia/autocomplete-js'].getAlgoliaResults;

                    function get_sources(o) {
                        var sources = [];

                        var shop_source = {
                            sourceId: 'shop_source',
                            getItems: function() {
                                return getAlgoliaResults({
                                    searchClient: _client,
                                    queries: [{
                                        indexName: 'shopify_collections',
                                        query: o.query,
                                        params: {
                                            hitsPerPage: 10,
                                            filters: "meta.global.collection_type:shop"
                                        }
                                    }]
                                });
                            },
                            onSelect: function(o) {
                                _AC_SHOP.update_shop(o.item.meta.global.shop_name);
                                o.setQuery('');
                            },
                            templates: {
                                item: function(o) {
                                    //console.log('templates item - o', o);
                                    var link = '<div class="shop_link">';
                                    link += '<span class="shop_icon" style="background-image: url(https://img.mlahart.com/' + o.item.meta.global.lpcode + '-menu.30.png)"></span>';
                                    link += '<span class="shop_name">' + o.item.meta.global.shop_name + '</span>';
                                    link += '</div>';
                                    return o.html([link]);
                                },
                                noResults: function(o) {
                                    //console.log('templates noResults - o', o);
                                    return o.html(['<div class="mlahart_dark_row">No results for "' + o.state.query + '"</div>']);
                                }
                            }

                        };
                        sources.push(shop_source);
                        return sources;
                    }

                    var autocomplete_config = {
                        container: '#autocomplete',
                        placeholder: _CONFIG.school_placeholder,
                        getSources: get_sources,
                        debug: false,
                        openOnFocus: true
                    };
                    _algolia_search = autocomplete(autocomplete_config);
                }

                function search_function(helper) {
                    var has_query = !!helper.state.query;

                    var shop_selected = !!helper.hasRefinements(shop_name_attr_path) || false;               

                    if (shop_selected || has_query) {
                        ctrl.make_search_active();
                    } else {
                        ctrl.make_search_inactive();
                    }


                    if (!ctrl.search_active) return;

                    if (_AC_SHOP) {
                        _AC_SHOP.uiSync(helper);
                    }

                    if (shop_selected || has_query) {
                        if (helper.state && helper.state.distinct) helper.setQueryParameter('distinct', false);
                    } else {
                        if (helper.state && !helper.state.distinct && _config.configure.distinct !== 'false') helper.setQueryParameter('distinct', true);
                    }    

                    helper.search();
                }

                function init_search(config) {
                    config.infiniteHits.transformItems = transform_items;

                    _search = instantsearch({ indexName: 'shopify_products', searchClient: _client,/* routing: routing, */searchFunction: search_function });

                    var virtual_menu = instantsearch.connectors.connectMenu(function(opts, is_first) { if (is_first) {} });
                    var virtual_toggle = instantsearch.connectors.connectToggleRefinement(function(opts, is_first) { if (is_first) {} });

                    var search_widgets = [
                        instantsearch.widgets.configure(config.configure),
                        instantsearch.widgets.searchBox(config.searchBox),
                        instantsearch.widgets.infiniteHits(config.infiniteHits)
                    ];

                    function add_gender_widget() {

                        function get_gender() {
                            var _gender = _search.helper.state.getHierarchicalRefinement(gender_attr_path);
                            return _gender[0] || false;
                        }

                        function gender_list_map(items) {
                            var _gen = [];

                            var _label_map_gender = {
                                "female": "Women's",
                                "male": "Men's",
                                "-male": "Women's",
                                "-female": "Men's"
                            };

                            var _value_map_gender = {
                                "female": "-male",
                                "male": "-female",
                                "-male": "-male",
                                "-female": "-female"
                            };

                            var active_filter = get_gender();

                            //console.log('_search.helper', _search.helper);
                            //console.log('active_filter', active_filter);

                            items.forEach(function(item) {
                                if (_label_map_gender[item.label] && _value_map_gender[item.value]) {
                                    item.label = _label_map_gender[item.label];
                                    item.value = _value_map_gender[item.value];
                                    item.isRefined = (item.value === active_filter);
                                    _gen.push(item);
                                }
                            });

                            //console.log('gender items', { items: items, _gen: _gen });

                            return _gen;
                        }

                        config.gender_menu.transformItems = gender_list_map;

                        search_widgets.push(instantsearch.widgets.menu(config.gender_menu));
                    }

                    if (_CONFIG.show_gender) add_gender_widget();

                    search_widgets.push(virtual_menu({ attribute: shop_name_attr_path }));
                    search_widgets.push(virtual_toggle({ attribute: 'on_sale' }));

                    _search.addWidgets(search_widgets);

                    _search.start();

                    setTimeout(function() {
                        loading = 'eager';
                    }, 2000);

                    if (_CONFIG.search_school_first) {
                        _AC_SHOP = ShopUi(_search.helper);

                        if (_AC_SHOP && typeof autocomplete !== 'undefined') {
                            add_autocomplete();
                        }
                    }   

                    return _search;
                }

                try {
                    init_search(_config);
                } catch (err) {
                    console.error(err);
                }

                return _client;
            }


            search_instance = init(_CONFIG);

        }


        ctrl.$onInit = function() {
            algolia_init();
            //console.log('-----------------------------------------------------tjbSearchBoxAlgolia - algolia_init - called');
        };
    };

    angular.module('tjb').component('tjbSearchBoxAlgolia', {
        templateUrl: 'components/tjb-search/tjb-search-box-algolia.html',
        controller: ['tjbItemService', 'tjbNsapi', '$timeout', tjbSearchBoxNsCtrl],
        controllerAs: '$ctrl',
    });
}());
(function() {
    'use strict';
    var tjbSearchTabCtrl = function(tjbItemService) {
        var ctrl = this;

        ctrl.featuredKit = {};

        ctrl.$onInit = function() {
            ctrl.data = tjbItemService.getData();
            ctrl.stats = tjbItemService.getSearch();
            //console.log('-----------------------------------------------------tjbSearchTab - called');
    

        };

        ctrl.$doCheck = function() {
            //console.log('tjbSearchTab - ctrl.$doCheck');
            
            if (!angular.equals(ctrl.featuredKit, tjbItemService.getFeatureKit())) {
                ctrl.featuredKit = tjbItemService.getFeatureKit();
            }
            

        };
        
    };

    angular.module('tjb').component('tjbSearchTab', {
        templateUrl: 'components/tjb-search/tjb-search-tab.html',
        controller: ['tjbItemService', tjbSearchTabCtrl],
        controllerAs: '$ctrl',
        bindings: {}
    });
}());

(function() {
    'use strict';
    var tjbShippingCtrl = function(tjbNsapi, tjbOrderService, $rootScope) {
        var ctrl = this;
        ctrl.processing = false;

        ctrl.selectShip = function(method) {
            if (ctrl.processing) return;
            ctrl.processing = true;
            //console.log(method);
            var shipid = method.internalid;
            if (shipid === ctrl.data.ns.cart.shipmethod) return false;
            if (shipid === "2741" && !ctrl.data.ns.state.expressIsAvailable) return false;
            tjbNsapi.updateShipping(shipid, function(res) {
                ctrl.processing = false;
                console.log(res);
            });
        };

        ctrl.$onInit = function() {
            ctrl.data = tjbOrderService.getData();
            try {
                if (ctrl.data.ns.state.shippingError && ctrl.data.ns.state.shipmethod !== "2739") {
                    ctrl.processing = true;
                    tjbNsapi.updateShipping("2739", function(res) {
                        ctrl.processing = false;
                        console.log(res);
                    });
                }
            } catch (err) {}

        };
    };

    angular.module('tjb').component('tjbShipping', {
        templateUrl: 'components/tjb-shipping/tjb-shipping.html',
        controller: ['tjbNsapi', 'tjbOrderService', '$rootScope', tjbShippingCtrl],
        controllerAs: '$ctrl'
    });
}());